














  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'OneCmsArticle',
    props: {
      imageUrl: {
        type: String,
        default: '',
      },
      url: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
    },
  })
